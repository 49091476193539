import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const body = document.body;

  ScrollTrigger.create({
    trigger: body,
    start: 'top+=1px top',
    onToggle: (self) => self.isActive ? body.classList.add('user-has-scrolled') : body.classList.remove('user-has-scrolled'),
  });
}