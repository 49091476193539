import axios from 'axios';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// There should only be one filters form per page
const form = document.getElementById('filters-form');
const toggleButton = document.getElementById('toggle-filters');
const activeSubMenuClass = 'sub-filter-menu-is-active';
const activeParentRadioClass = 'parent-radio-is-active';
const unfilteredClass = 'is-unfiltered';
const featuredLayoutClass = 'feautured-layout';
let dropdownButton;
let dropdownMenu;

function showFilters() {
  toggleButton.setAttribute('aria-expanded', 'true');
  toggleButton.innerHTML = 'Hide filters';
  // Remove initial form-is-hidden-default class, as this is only to apply initial height: 0.
  // Otherwise would interfere with hiding animation
  // form-is-hidden class is just for toggling
  form.classList.remove('form-is-hidden', 'form-is-hidden-default');
  gsap.set(form, {clearProps: true});
  gsap.from(form, {
    autoAlpha: 0,
    height: 0,
    ease: 'power2.out',
    duration: 0.2,
    clearProps: true
  });
}

function hideFilters() {
  toggleButton.setAttribute('aria-expanded', 'false');
  toggleButton.innerHTML = 'Show filters';
  form.classList.add('form-is-hidden');
  gsap.to(form, {
    autoAlpha: 0,
    height: 0,
    ease: 'power2.out',
    duration: 0.2,
  });
}

function toggleFilters() {
  form.classList.contains('form-is-hidden') || form.classList.contains('form-is-hidden-default') ? showFilters() : hideFilters();
}

function initFilterDropdown() {
  // Entry type checkbox dropdown menu
  dropdownButton = document.getElementById('filter-menu-dropdown-button');
  dropdownMenu = document.getElementById('filter-menu-dropdown');
  if (!dropdownButton) return;
  dropdownButton.addEventListener('click', handleDropdownClick);
}

function handleDropdownClick() {
  const isActive = dropdownButton.getAttribute('aria-expanded') === 'true';
  isActive ? hideFilterDropdown() : showFilterDropdown();
}

function showFilterDropdown() {
  dropdownButton.setAttribute('aria-expanded', 'true');
  const tl = gsap.timeline();
  tl.set(dropdownMenu, {
    height: 'auto',
    autoAlpha: 1,
    overflow: 'visible'
  })
  .from(dropdownMenu, {
    height: 0,
    duration: 0.1,
    ease: 'power2.out',
    immediateRender: false,
    autoAlpha: 0,
    overflow: 'hidden'
  });
}

function hideFilterDropdown() {
  dropdownButton.setAttribute('aria-expanded', 'false');
  gsap.to(dropdownMenu, {
    height: 0,
    duration: 0.05,
    ease: 'none',
    autoAlpha: 0,
    overflow: 'hidden'
  });
}

function ajax(options) {
  // Extract variables and their values using ES6 object desctructing :)
  // Set default values for paginate, updateHistory, queryParamsEmpty
  const { url, paginate = false, updateHistory = true, queryParamsEmpty = false } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('loading-ajax-cards');
  // Use ajax to get the new url then change the items in the grid dynamically
  axios.get(url).then((res) => {
    if (history && updateHistory) {
      history.pushState('filters', '', url);
    }
    const ajaxContainer = document.getElementById('ajax-cards');
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    const newContent = newContainer.innerHTML;
    // Check whether to add or remove the 'featured-layout' class
    ajaxContainer.dataset.page = newContainer.dataset.page;
    ajaxContainer.dataset.page === 1 ? ajaxContainer.classList.add(featuredLayoutClass) : ajaxContainer.classList.remove(featuredLayoutClass);
    queryParamsEmpty ? ajaxContainer.classList.add(unfilteredClass) : ajaxContainer.classList.remove(unfilteredClass);
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;
    ajaxContainer.innerHTML = newContent;
    ajaxContainer.style.height = `auto`;

    gsap.from('.js-ajax-card', {
      opacity: 0,
      y: 10,
      duration: 0.4,
      stagger: 0.1,
    });

    if (paginate) {
      gsap.to(window, {
        scrollTo: {
          y: form,
          offsetY: () => {
            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('loading-ajax-cards');
  });
}

function hideSubFilterMenu(parentRadio) {
  parentRadio.setAttribute('aria-expanded', false);
  parentRadio.classList.remove(activeParentRadioClass);
  const selector = parentRadio.dataset.subfilterMenu;
  const subfilterMenu = document.getElementById(selector);
  if (!subfilterMenu) return;
  subfilterMenu.classList.remove(activeSubMenuClass);
  // Uncheck subfilter checkboxes
  const checkboxes = subfilterMenu.querySelectorAll('input[type="checkbox"]');
  checkboxes.forEach(checkbox => checkbox.checked = false);
  const tl = gsap.timeline();
  tl.set(subfilterMenu, {
    autoAlpha: 0,
    position: 'absolute'
  });
}

function showSubFilterMenu(parentRadio) {
  // Check if another subfilter menu is already open
  // If so, then hide it
  const activeParentRadio = document.querySelector(`.${activeParentRadioClass}`);
  // If there is a currently active parent radio, de-select it
  if (activeParentRadio) hideSubFilterMenu(activeParentRadio);
  // Then show newly active menu
  parentRadio.setAttribute('aria-expanded', true);
  parentRadio.classList.add(activeParentRadioClass);
  const selector = parentRadio.dataset.subfilterMenu;
  const subfilterMenu = document.getElementById(selector);
  const tl = gsap.timeline();
  tl.set(subfilterMenu, {
    height: 'auto',
    autoAlpha: 1,
    overflow: 'visible',
    position: 'relative'
  })
  .from(subfilterMenu, {
    // If there was an active submenu then we don't animate the height change (because we want to avoid the entries below 'jumping' position)
    height: activeParentRadio ? 'auto' : 0,
    duration: 0.1,
    ease: 'power2.out',
    immediateRender: false,
    autoAlpha: 0,
    overflow: 'hidden'
  });
}

function highlightParent(el) {
  const parentRadioSelector = el.dataset.parentRadio;
  const parentRadio = document.getElementById(parentRadioSelector);
  if (!parentRadio) return;
  parentRadio.classList.add(activeParentRadioClass);
}

function updateTypeCounter(entryTypes) {
  const activeEntryTypes = Array.from(entryTypes).filter(item => item.checked);
  const counterEl = document.getElementById('type-filter-counter');
  // Update counter
  activeEntryTypes.length > 0 ? counterEl.innerHTML = `[${activeEntryTypes.length}]` : counterEl.innerHTML = '';
}

function handle(e) {
  if (e && e.type === 'submit') {
    e.preventDefault();
  }
  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?')[0];
  // Rebuild URL querystring from checked filters
  // Normally we'd do an if (checked.length) but in this case the 'All' button is a radio button so it means at least one input is always checked
  const queryParams = new URLSearchParams();
  // Check if radio input is selected. If it's a top-level radio input show its submenu
  const clicked = e && e.target.closest('input');
  const clickedType = clicked ? clicked.type : null;

  if (clickedType === 'radio') {
    // Check if radio is the reset/all radio
    const isReset = clicked.classList.contains('js-filter-reset');
    if (isReset) {
      const active = document.querySelector(`.${activeParentRadioClass}`);
      if (active) {
        hideSubFilterMenu(active);
      }
    } else {
      // Show radio submenu
      showSubFilterMenu(clicked);
    }
  } else if (clickedType === 'checkbox') {
    const parentRadio = document.getElementById(clicked.dataset.parentRadio);
    if (parentRadio) {
      // If any subfilter checkbox is checked then uncheck parent radio so that we specifically filter by the subcategory
      const menuEl = clicked.closest('menu');
      const activeCheckboxes = menuEl.querySelectorAll('input[type="checkbox"]:checked');
      activeCheckboxes.length ? parentRadio.checked = false : parentRadio.checked = true;
      // But keep parent radio styled as if it's checked to help indicate a 'child' checkbox is selected
      highlightParent(clicked);
    }
  }
  // Check if search results page search input has value (separate from header searchbar)
  const searchForm = document.getElementById('search-page-search');
  if (searchForm) {
    const searchInput = searchForm.querySelector('input');
    if (searchInput && searchInput.value) queryParams.append('q', searchInput.value);
  }
  // Get and append all entryTypes to URL
  const activeFilters = Array.from(form.querySelectorAll('[name="filters[]"]:checked'), function(el) { return el.value; }).join('|');
  if (activeFilters.length) queryParams.append('filters', activeFilters);
  const entryTypeFilters = form.querySelectorAll('[data-filter-type="entryType"]');
  // If entryType checkboxes update entry type counter element
  if (entryTypeFilters.length) updateTypeCounter(entryTypeFilters);
  // Check if any parameters present before adding '?' to URL:
  const queryParamsEmpty = queryParams.toString().length === 0;
  url = new URL (`${url}${queryParamsEmpty ? '' : '?'}${queryParams}`);
  ajax({
    url: url,
    queryParamsEmpty: queryParamsEmpty
  });
}

function resetFilters() {
  const filters = Array.from(form.querySelectorAll('.js-filter'));
  filters.forEach(filter => filter.checked = false);
  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?')[0];
  ajax({url: url});
}

function paginate(link) {
  const url = new URL(link.href);
  // Check if url contains a querystring before we call the ajax function so it knows whether to apply the 'is-unfiltered' class for layout stuff (e.g. featured first card layout)
  // Probably a nicer way of doing this but we need to be sure there isn't some other random querystring in the URL from a tracking tool in production etc.
  const qString = url.searchParams.toString();
  // Check if our filters are present in the querystring
  const queryParams = qString.includes('filters');
  ajax({
    url: url, 
    paginate: true,
    queryParamsEmpty: !queryParams
  });
}

function init() {
  if (!form) return;
  const filters = document.querySelectorAll('.js-filter');
  if (toggleButton) toggleButton.addEventListener('click', toggleFilters);

  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const paginateLink = clicked.closest('.js-filter-paginate-link');
    const resetButton = clicked.closest('#reset-filters');
    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    } else if (resetButton) {
      resetFilters();
    }
  });

  form.addEventListener('submit', handle);
  filters.forEach(filter => filter.addEventListener('change', handle));
  // Initialise the entry type dropdown checkbox menu:
  initFilterDropdown();

  window.addEventListener('popstate', (e) => {
    const pageHasFilters = e.state === 'filters';
    if (!pageHasFilters) return;
    // TODO: fix subfilter selection on popstate event, especially if subfilter was already selected on initial page load
    // TEMPORARY fix is to reload the page, obviously not ideal..
    window.location.reload();
  });
}

const filters = {
  handle: handle,
  init: init
}

export default filters;
