import dropdowns from './dropdowns';
import filters from './filters';

const body = document.body;

function focusSearch() {
  body.classList.add('search-is-active');
  const headerSearchForm = document.getElementById('search-header-search');
  if (headerSearchForm) {
    const searchInput = headerSearchForm.querySelector('input');
    searchInput.focus();
  }
}

function init() {
  // Header search dropdown functionality already handled by dropdown.js module
  const searchCloseButton = document.getElementById('search-close-button');
  const searchPageForm = document.getElementById('search-page-search');

  // Search close button will always exist because it's in the header on every page
  searchCloseButton.addEventListener('click', function() {
    dropdowns.hideMenu();
  });

  // Check if separate in-page form is present. 
  // If so, use the filters handler function to run the ajax filtering
  if (searchPageForm) {
    searchPageForm.addEventListener('submit', function(e) {
      filters.handle(e);
    });
  }
}

const search = {
  focusSearch: focusSearch,
  init: init
}

export default search;