import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

const activeSideNavMenuClass = 'side-nav-is-open';
const activeLinkClass = 'scroll-link-is-active';
const linkTargetClass = 'js-scroll-link-target';
let sideNav;
let sideNavMenu;
let sideNavMenuToggle;

function getNextSibling(elem, selector = `.${linkTargetClass}`) {
	// Get the next sibling element
	let sibling = elem.nextElementSibling;
	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling;
	}
};

function updateActiveLink(el) {
  // Check if active link
  const activeLink = document.querySelector(`.${activeLinkClass}`);
  if (activeLink) activeLink.classList.remove(activeLinkClass);
  // Find matching link
  // Use the '$' symbol in the selector (so we don't need to worry about the rest of the URL in the href):
  // https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors#syntax
  const match = document.querySelector(`.js-scroll-link[href$="${el.id}"]`);
  if (match) match.classList.add('scroll-link-is-active');
}

function initScrollTrigger(el) {
  // We want the active side-nav link to stay highlighted until the next section comes into view.
  // The problem is that the scroll link targets have other content after them that visually is meant to belong to them:
  // Example: https://www.figma.com/proto/7yc18qDyj16BONBkXAlPq0/IHRB-Design-V4?type=design&node-id=6002-7280&t=yNXjpxxhGAUX3PFf-0&scaling=min-zoom&page-id=4016%3A5341
  // So we can use the next scroll link target (if one exists) as the endTrigger for the current scrollTrigger:
  const endTrigger = getNextSibling(el) ? getNextSibling(el) : el;
  ScrollTrigger.create({
    // markers: true,
    trigger: el,
    start: 'top top',
    end: 'top top',
    endTrigger: endTrigger,
    onEnter() {
      updateActiveLink(el);
    },
    onEnterBack() {
      updateActiveLink(el);
    }
  });
}

function handleClick(e) {
  e.preventDefault();
  const link = e.target.closest('.js-scroll-link');
  const selector = link.getAttribute('href');
  const match = document.querySelector(selector);
  if (!match) return;
  gsap.to(window, {
    scrollTo: {
      y: match,
      // Seems like the -1 is needed to for scrollTrigger to recognise the active section has been reached
      offsetY: -1
    }
  });
}

function hideSideNavMenu() {
  sideNav.classList.remove(activeSideNavMenuClass);
  sideNavMenuToggle.ariaExpanded = 'false';
  // We use display:none so that keyboard users don't have to tab through links in a closed menu
  gsap.to(sideNavMenu, {display: 'none', height: 0, duration: 0.1});
}

function showSideNavMenu() {
  sideNav.classList.add(activeSideNavMenuClass);
  sideNavMenuToggle.ariaExpanded = 'true';
  const tl = gsap.timeline();
  tl.set(sideNavMenu, {display: 'block', height: 'auto', opacity: 1});
  tl.from(sideNavMenu, {height: 0, duration: 0.2, immediateRender: false});
}

function toggleSideNavMenu() {
  sideNav.classList.contains(activeSideNavMenuClass) ? hideSideNavMenu() : showSideNavMenu();
}

function initSideNavMenu() {
  sideNav = document.getElementById('side-nav');
  if (!sideNav) return;
  sideNavMenu = sideNav.querySelector('#side-nav-content');
  sideNavMenuToggle = sideNav.querySelector('#side-nav-toggle');

  // Use GSAP matchMedia to switch between mobile and desktop states for dropdown menu
  // https://gsap.com/docs/v3/GSAP/gsap.matchMedia()/#interactivity-and-cleanup
  let mm = gsap.matchMedia();
  const breakPoint = 768;

  mm.add({
    isDesktop: `(min-width: ${breakPoint}px)`,
    isMobile: `(max-width: ${breakPoint - 1}px)`,
  }, (context) => {
    let { isDesktop, isMobile } = context.conditions;
    // For accessibility:
    // If desktop 
    if (isDesktop) {
      showSideNavMenu();
      sideNavMenuToggle.tabIndex = '-1';
    } else if (isMobile) {
      // If mobile
      context.add("onClick", toggleSideNavMenu);
      hideSideNavMenu();
      sideNavMenuToggle.removeAttribute('tabindex');
      sideNavMenuToggle.addEventListener("click", context.onClick);

      return () => {
        // make sure to clean up event listeners in the cleanup function!
        sideNavMenuToggle.removeEventListener("click", context.onClick);
      };
    }
  });
}

function initScrollLinks() {
  // Set up scroll links and matching elements
  const scrollLinks = document.querySelectorAll('.js-scroll-link');
  const scrollLinkTargets = document.querySelectorAll(`.${linkTargetClass}`);
  scrollLinks.forEach(link => link.addEventListener('click', handleClick));
  scrollLinkTargets.forEach(el => initScrollTrigger(el));
}

export default function() {
  initSideNavMenu();
  // Wait for full page load so scrollTrigger doesn't miscalculate positions
  window.addEventListener('load', initScrollLinks);
}