import gsap from 'gsap';
import search from './search';

const hideTime = 0.1;
const buttonSelector = '.js-dropdown-button';
const activeButtonSelector = `${buttonSelector}[aria-expanded="true"]`;

function checkActive() {
  const active = document.querySelector(activeButtonSelector);
  if (active) {
    showMenu(active, false);
  }
}

// This basically helps keep the nav in sync when switching between mobile and desktop styles
// https://gsap.com/docs/v3/HelperFunctions/helpers/callAfterResize
function callAfterResize(func, delay) {
  let dc = gsap.delayedCall(delay || 0.2, func).pause();
  let handler = () => dc.restart(true);
  window.addEventListener("resize", handler);
  return handler; // in case you want to window.removeEventListener() later
}

function handleEscape(e) {
  if (e.code === 'Escape') {
    hideMenu();
  }
}

function hideMenu(el) {
  const button = el || document.querySelector(activeButtonSelector);
  if (!button) return;
  const menu = button.nextElementSibling;
  button.setAttribute('aria-expanded', 'false');
  gsap.to(menu, {
    height: 0,
    duration: hideTime,
    ease: 'none',
    autoAlpha: 0
  });
}

function showMenu(el, animate = true) {
  const button = el;
  const active = document.querySelector(activeButtonSelector);
  if (button !== active) {
    hideMenu(active);
  }
  button.setAttribute('aria-expanded', 'true');
  const menu = button.nextElementSibling;
  const tl = gsap.timeline({delay: active ? hideTime : 0});
  tl.set(menu, {
    height: 'auto',
    autoAlpha: 1
  })
  .from(menu, {
    height: 0,
    duration: animate ? 0.2 : 0,
    ease: 'power2.out',
    immediateRender: false,
    autoAlpha: 0,
    onComplete() {
      if (menu.id === 'menu-search') {
        search.focusSearch();        
      }
    }
  })
}

function handleClick(e) {
  const button = e.target.closest(buttonSelector);
  const clickFromInsideHeader = e.target.closest('#header');

  if (button) {
    const isActive = button.getAttribute('aria-expanded') === 'true';
    isActive ? hideMenu(button) : showMenu(button);
  } else if (!clickFromInsideHeader) {
    hideMenu();
  }
}

function init() {
  document.addEventListener('click', handleClick);
  document.addEventListener('keyup', handleEscape);
  callAfterResize(checkActive);
}

const dropdowns = {
  hideMenu: hideMenu,
  init: init
}

export default dropdowns;
